import React from 'react';

const IconNumismatics = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 600 600">
    <title>Numismatics</title>
<g transform="translate(-50.000000,650.000000) scale(0.180000,-0.180000)"
 stroke="none">
<path d="M1715 3511 c-368 -67 -659 -216 -905 -461 -214 -214 -358 -475 -415
-755 -22 -109 -32 -394 -16 -476 7 -35 32 -135 56 -221 48 -173 105 -300 163
-364 20 -22 73 -85 117 -139 115 -142 256 -281 325 -319 19 -11 40 -24 45 -28
29 -24 84 -56 106 -61 13 -4 48 -22 78 -41 96 -62 313 -129 509 -157 127 -18
442 -6 567 21 150 32 296 83 430 150 430 216 734 604 832 1060 26 121 26 449
0 570 -62 290 -202 545 -417 760 -226 226 -511 378 -845 451 -144 31 -486 37
-630 10z m500 -52 c193 -21 347 -51 353 -68 2 -6 7 -9 12 -6 25 16 235 -83
377 -177 118 -79 282 -234 345 -328 21 -30 66 -92 100 -137 35 -45 74 -109 87
-142 l24 -60 -32 -26 c-17 -15 -31 -34 -31 -42 0 -24 -101 -173 -117 -173 -15
0 -9 41 12 75 6 11 20 45 30 75 9 30 23 60 31 66 14 11 38 103 29 112 -3 3
-12 -4 -21 -16 -19 -27 -40 -28 -54 -3 -15 29 -13 31 20 25 17 -4 30 -4 30 0
0 10 -23 48 -41 66 -9 10 -18 23 -21 30 -7 21 -58 -18 -58 -44 -1 -25 -18 -50
-42 -59 -10 -3 -18 -12 -18 -19 0 -6 -8 -21 -18 -32 -10 -12 -26 -41 -36 -66
-17 -47 -57 -100 -75 -100 -14 0 7 57 58 157 24 49 37 85 31 88 -18 11 29 65
56 65 16 0 23 4 18 11 -3 6 0 15 7 20 12 8 12 10 0 18 -8 5 -20 7 -28 4 -7 -3
-13 0 -13 6 0 7 -16 21 -35 32 -19 12 -32 26 -28 32 4 6 -2 8 -15 5 -26 -7
-26 -7 -7 38 8 20 13 40 10 44 -3 4 -26 7 -52 6 -51 -1 -54 -5 -70 -103 -3
-18 -9 -33 -13 -33 -4 0 -18 -33 -31 -72 -25 -79 -77 -178 -114 -218 -31 -34
-45 -64 -45 -94 0 -20 8 -29 36 -41 68 -28 81 -20 108 69 16 52 76 146 128
199 21 22 34 45 31 53 -3 9 2 14 16 14 13 0 21 -6 21 -17 0 -26 -31 -104 -57
-143 -13 -19 -23 -41 -23 -48 0 -7 -20 -33 -45 -59 -46 -47 -55 -75 -31 -105
21 -28 100 -25 120 4 9 12 16 29 16 36 0 7 7 20 15 28 8 9 15 24 15 35 0 24
48 27 57 4 3 -9 20 -22 37 -30 l31 -13 -32 -1 c-17 -1 -35 4 -38 9 -12 20 -25
9 -25 -21 0 -16 4 -28 9 -25 15 10 43 -6 37 -20 -3 -7 1 -26 9 -41 8 -15 12
-37 9 -49 -8 -32 27 -53 78 -46 32 4 44 1 54 -13 18 -25 27 -14 27 35 0 38 17
55 32 30 11 -18 23 -11 18 10 -4 14 0 20 10 20 13 0 16 10 15 43 -3 76 2 147
11 147 13 0 41 -82 52 -152 11 -68 15 -421 5 -436 -3 -5 -8 -52 -12 -103 -7
-112 -30 -216 -53 -244 -10 -11 -23 -44 -29 -73 -7 -29 -23 -68 -37 -86 -14
-18 -25 -38 -25 -44 0 -6 -10 -18 -22 -26 -12 -9 -19 -16 -15 -16 11 0 -28
-59 -65 -101 -10 -10 -18 -23 -18 -29 0 -5 -4 -10 -8 -10 -11 0 -39 -27 -82
-78 -19 -23 -38 -42 -42 -42 -5 0 -34 -19 -67 -43 -52 -37 -79 -55 -182 -119
-15 -9 -46 -22 -68 -28 -22 -6 -46 -17 -53 -24 -8 -7 -32 -19 -53 -27 -22 -7
-52 -21 -67 -31 -14 -10 -31 -18 -37 -18 -6 0 -11 -4 -11 -10 0 -15 -27 -12
-44 5 -19 19 -28 19 -79 -5 -23 -11 -66 -22 -95 -24 -30 -3 -81 -10 -114 -16
-33 -6 -74 -8 -91 -5 -59 13 -127 37 -127 46 0 5 -6 9 -12 9 -19 0 -103 100
-133 157 -14 26 -33 54 -43 61 -48 36 -102 88 -102 98 0 6 -13 21 -30 34 -16
13 -30 28 -30 35 0 11 -73 85 -85 85 -3 0 -23 17 -44 38 -21 20 -49 43 -62 50
-79 42 -149 92 -149 107 0 8 -7 15 -15 15 -8 0 -17 11 -21 25 -5 20 -11 24
-30 21 -22 -4 -29 3 -25 27 2 10 -48 29 -59 22 -4 -3 -15 6 -23 19 -14 21 -14
28 -1 66 22 62 -2 85 -61 58 -18 -8 -24 -17 -20 -29 8 -25 -28 -44 -56 -29
-12 7 -18 18 -15 26 3 8 3 28 -1 44 -4 23 -11 30 -30 30 -13 0 -22 -4 -19 -9
3 -5 -17 -11 -45 -13 -28 -3 -55 -11 -60 -19 -12 -20 -22 -4 -15 25 11 44 49
60 137 59 43 0 84 -5 92 -11 9 -8 33 -5 87 10 41 11 104 27 140 36 36 8 68 19
71 24 3 5 13 0 23 -11 12 -14 14 -21 6 -26 -9 -6 -9 -9 0 -15 8 -5 -8 -15 -41
-28 -84 -31 -109 -44 -103 -53 3 -5 17 -6 32 -3 26 7 27 6 10 -13 -27 -29 -22
-40 20 -48 45 -8 63 -21 55 -41 -4 -10 2 -14 20 -14 31 0 79 -24 69 -35 -4 -4
2 -3 13 3 11 5 36 10 56 10 20 0 46 5 57 11 11 6 32 11 47 11 32 0 105 22 105
32 0 4 -8 5 -17 1 -13 -4 -15 -3 -8 5 6 5 38 14 73 20 51 7 62 6 58 -5 -3 -8
2 -13 14 -13 10 0 43 -6 72 -12 l53 -13 -70 -6 c-201 -20 -208 -21 -231 -48
-20 -23 -24 -24 -46 -12 -21 11 -31 10 -61 -3 -21 -8 -37 -20 -37 -27 0 -7
-11 -9 -32 -5 -18 3 -40 1 -48 -4 -13 -8 -13 -10 1 -10 9 0 22 -7 29 -15 7 -8
19 -15 28 -15 21 0 52 -29 52 -49 1 -23 39 -72 40 -49 0 9 6 19 13 22 10 5 10
7 0 12 -20 9 -15 42 6 54 11 5 22 20 24 32 4 21 4 21 4 -2 0 -14 4 -33 8 -43
4 -10 3 -29 -4 -43 -14 -31 -14 -64 -1 -64 5 0 22 -18 36 -40 15 -22 30 -37
35 -34 5 3 9 1 9 -4 0 -5 48 -65 106 -133 58 -68 126 -155 152 -193 65 -98
135 -166 169 -166 76 0 243 36 243 52 0 6 7 8 14 5 10 -4 13 -1 9 9 -3 9 1 14
13 12 48 -4 55 1 43 24 -20 37 -66 78 -88 78 -22 0 -30 -29 -8 -31 6 0 -1 -5
-17 -11 -22 -8 -30 -7 -37 3 -7 11 -9 10 -9 -3 0 -25 -19 -22 -29 5 -11 28 -3
53 12 38 16 -16 28 -13 26 6 -1 10 -1 28 0 41 l1 22 21 -21 c13 -13 27 -18 40
-14 11 4 19 2 19 -4 0 -6 7 -11 15 -11 27 0 16 30 -17 44 -18 8 -27 15 -20 15
6 1 12 8 12 17 0 11 -6 15 -20 11 -11 -3 -23 -1 -26 4 -3 5 2 9 10 9 9 0 16 6
16 14 0 27 37 5 53 -33 10 -21 17 -43 17 -49 0 -7 7 -12 15 -12 8 0 15 4 15 8
0 5 17 6 38 2 22 -4 36 -11 34 -19 -1 -7 1 -10 5 -8 5 3 19 1 32 -4 33 -13 15
-30 -33 -32 -29 -2 -33 -4 -21 -13 8 -6 11 -15 7 -19 -4 -5 -1 -5 6 0 8 4 25
1 40 -6 20 -11 26 -11 29 -1 3 6 12 12 21 13 14 0 14 2 -2 8 -12 5 -20 18 -21
37 -1 16 -5 47 -9 69 l-8 40 36 -34 c27 -26 36 -31 36 -18 0 9 5 17 11 17 5 0
7 5 4 10 -3 6 -15 10 -26 10 -10 0 -19 7 -19 15 0 22 14 19 48 -10 21 -17 33
-22 38 -14 5 8 9 7 15 -2 6 -11 11 -11 21 -1 19 19 28 14 27 -16 -1 -15 3 -38
8 -51 9 -23 10 -22 16 19 3 23 11 47 19 51 9 6 4 12 -20 21 -18 7 -30 18 -28
24 5 13 -35 34 -66 34 -17 0 -18 -2 -8 -15 10 -12 9 -15 -6 -15 -24 0 -95 50
-88 62 3 5 -3 4 -15 -4 -16 -10 -23 -10 -33 0 -6 6 -19 12 -28 12 -16 1 -43
21 -73 57 -19 21 -18 22 2 44 21 23 22 23 52 5 35 -21 113 -15 123 9 3 8 14
15 24 15 10 0 27 9 37 20 10 11 24 20 31 20 8 0 17 12 20 26 6 25 44 73 44 56
0 -4 14 -13 31 -19 25 -10 29 -16 24 -32 -10 -32 4 -45 26 -25 20 18 27 6 8
-13 -8 -8 -5 -14 10 -22 13 -8 21 -8 21 -2 0 6 13 8 30 5 19 -4 30 -2 30 5 0
6 -5 11 -12 11 -9 0 -9 3 0 12 8 8 15 8 27 -2 9 -7 15 -8 15 -2 0 6 7 12 15
14 10 2 12 8 5 17 -7 12 -12 12 -23 3 -15 -12 -47 -7 -47 8 0 5 -10 6 -22 2
-14 -3 -19 -2 -14 6 4 7 1 12 -6 12 -7 1 -2 7 12 15 24 14 23 16 -9 19 -8 0
-16 6 -18 13 -5 14 34 36 52 30 13 -6 35 10 35 25 0 4 -11 8 -24 8 -13 0 -27
8 -31 18 -5 13 -11 7 -25 -28 -19 -49 -52 -69 -48 -29 2 16 -3 24 -17 27 -26
5 -56 32 -49 43 13 22 12 49 -2 49 -9 0 -27 9 -40 19 -23 18 -24 20 -7 30 17
10 16 12 -7 31 -21 17 -23 21 -10 30 8 5 21 10 28 10 6 0 12 7 12 16 0 12 -4
14 -12 7 -7 -6 -25 -13 -39 -16 -21 -5 -28 -2 -37 18 -10 22 -33 31 -65 26
-13 -2 -25 42 -22 82 2 20 0 37 -3 37 -4 0 -12 11 -18 24 -10 22 -10 23 22 16
18 -4 44 -16 58 -26 29 -22 186 -218 186 -232 0 -6 9 -8 19 -5 11 3 44 -7 73
-21 37 -18 64 -25 88 -22 48 6 48 6 23 16 -13 5 -23 14 -23 20 0 8 37 8 48 -1
1 -1 7 -2 12 -3 6 0 14 -6 18 -12 4 -6 27 -14 52 -17 25 -3 39 -4 33 -1 -7 3
-13 12 -13 20 0 8 -8 17 -17 21 -59 21 -156 109 -223 202 -16 21 -38 42 -49
46 -21 6 -29 25 -12 25 10 0 69 -46 110 -86 58 -57 71 -67 77 -61 4 3 -2 14
-11 24 -17 17 -17 18 16 25 51 10 147 -2 145 -18 -1 -7 -6 -17 -11 -21 -4 -4
-5 -1 -1 7 4 8 0 6 -9 -5 -9 -11 -21 -19 -27 -17 -6 2 -18 0 -26 -3 -13 -5
-12 -8 3 -19 10 -7 27 -11 37 -8 10 2 25 -1 33 -8 8 -7 21 -10 29 -7 7 3 18 1
24 -5 6 -6 18 -8 27 -4 14 5 15 9 5 22 -7 8 -19 13 -27 10 -7 -3 -13 -1 -13 5
0 16 31 23 42 8 7 -10 8 -8 3 9 -5 18 -2 22 17 22 14 0 34 -9 46 -20 23 -21
57 -26 67 -10 3 6 -1 10 -9 10 -24 0 -19 16 10 35 14 9 23 22 21 30 -3 7 0 18
6 24 8 8 4 11 -15 11 -15 0 -30 5 -33 10 -6 10 7 14 35 10 13 -1 13 0 0 14
-13 13 -95 35 -200 53 -19 3 -37 9 -40 12 -3 4 -66 19 -140 34 -227 47 -312
68 -465 116 -129 41 -161 50 -212 61 -23 5 -70 18 -104 30 -42 13 -66 17 -73
10 -5 -5 -19 -10 -30 -10 -19 0 -19 -1 -4 -17 14 -13 20 -14 32 -4 10 8 16 9
21 1 3 -5 23 -10 45 -10 34 0 38 -2 36 -25 0 -14 -8 -28 -17 -30 -15 -5 -67
12 -79 25 -3 3 -24 12 -47 20 -46 14 -53 24 -26 34 14 6 15 9 2 20 -18 17 -79
29 -79 16 0 -6 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 4 10 9 10 5 0 11 7 15
15 4 12 -3 15 -35 15 -24 0 -38 -4 -34 -10 9 -15 -7 -12 -52 10 -41 19 -78 63
-68 79 3 5 -2 16 -10 24 -8 9 -15 19 -15 23 0 4 -8 16 -17 26 -14 16 -15 23
-5 38 7 12 8 21 2 25 -6 4 -1 15 12 25 l23 19 -23 1 c-26 0 -63 17 -74 34 -14
21 -9 -11 7 -40 8 -16 15 -39 15 -52 0 -21 -1 -22 -24 -7 -21 14 -26 14 -52 0
-16 -8 -39 -15 -51 -15 -12 0 -35 -8 -51 -17 -33 -19 -22 -25 -135 72 -9 8
-20 15 -24 15 -3 0 -14 6 -22 14 -14 13 -132 56 -153 56 -4 0 -8 -5 -8 -11 0
-5 5 -7 10 -4 7 4 9 -2 5 -18 -5 -20 -2 -27 15 -32 13 -4 33 -2 45 5 20 11 28
9 57 -13 18 -14 49 -33 68 -44 90 -47 134 -102 73 -90 -16 3 -36 9 -45 12 -12
5 -18 2 -18 -8 0 -16 -16 -16 -79 -2 -30 6 -33 5 -27 -10 5 -13 2 -16 -10 -11
-9 4 -26 4 -37 0 -18 -6 -19 -7 -4 -16 28 -15 19 -29 -17 -26 -26 1 -39 -4
-59 -28 -32 -37 -48 -24 -18 17 18 24 20 33 10 45 -9 11 -8 18 6 34 21 23 66
35 125 32 l45 -2 -35 15 c-42 19 -80 53 -80 72 0 9 -16 13 -55 13 -30 0 -55
-4 -55 -9 0 -4 -20 -19 -44 -32 -27 -14 -47 -33 -51 -46 -3 -13 -10 -23 -15
-23 -6 0 -10 -7 -10 -15 0 -8 5 -15 10 -15 6 0 13 7 16 15 9 22 61 19 74 -4
14 -28 12 -67 -4 -80 -23 -20 -80 -10 -84 14 -7 45 -10 47 -41 30 -16 -8 -37
-25 -45 -37 -19 -27 -28 -20 -29 24 -1 47 77 165 135 203 23 15 53 39 66 52
18 20 23 22 28 9 9 -25 22 -19 38 17 8 17 21 39 28 48 24 30 90 69 116 69 25
0 25 0 7 -20 -10 -11 -32 -24 -49 -28 -18 -5 -36 -20 -47 -38 l-16 -30 29 4
c19 3 44 19 64 42 19 21 43 40 54 43 25 6 25 2 0 -32 l-19 -28 34 -10 c32 -10
33 -10 39 22 5 25 14 36 36 43 28 10 29 10 25 -16 -5 -24 0 -30 41 -50 39 -19
45 -20 40 -6 -5 12 6 29 36 56 43 39 58 46 58 28 0 -5 -5 -10 -10 -10 -6 0 -8
-11 -4 -27 6 -24 5 -26 -8 -15 -9 7 -23 10 -32 6 -18 -7 -21 -20 -6 -29 6 -4
9 -14 8 -23 -2 -11 3 -16 12 -14 8 2 23 4 32 5 10 1 18 5 18 9 0 13 40 3 61
-16 16 -14 18 -20 9 -32 -22 -26 23 -16 71 15 26 17 65 32 95 36 44 6 58 3
111 -24 33 -17 68 -31 77 -31 10 0 14 -4 11 -10 -3 -5 -15 -10 -26 -10 -11 0
-17 -4 -14 -10 3 -5 20 -10 38 -10 19 0 41 -9 56 -24 15 -13 48 -29 76 -35 27
-7 57 -20 66 -30 17 -18 50 -29 169 -56 41 -10 84 -23 95 -30 11 -6 45 -20 75
-30 30 -10 69 -24 87 -32 17 -7 38 -13 46 -13 9 0 28 -5 44 -11 l28 -12 -25
-7 c-14 -5 -18 -8 -9 -9 19 -1 48 -31 31 -31 -6 0 -14 5 -17 10 -3 6 -17 10
-30 10 -14 0 -26 7 -30 19 -3 11 -12 22 -20 25 -9 3 -12 0 -8 -10 7 -18 -9
-18 -79 4 -70 22 -87 36 -33 28 38 -6 38 -5 10 5 -16 6 -39 14 -50 19 -11 5
-33 11 -50 13 -16 3 -48 10 -70 16 -40 12 -114 27 -170 36 l-30 4 25 -19 c14
-10 26 -17 28 -15 8 8 161 -44 184 -63 9 -7 51 -21 94 -32 67 -17 153 -46 239
-80 105 -43 358 -110 422 -112 21 -1 40 -5 43 -9 4 -5 22 -12 40 -15 25 -5 32
-3 25 4 -13 14 -246 92 -288 96 -17 2 -45 9 -60 15 -15 6 -39 13 -52 16 -14 3
-36 7 -50 10 -14 3 -25 10 -25 15 0 5 10 6 23 3 12 -3 45 -9 72 -14 28 -4 68
-13 90 -18 49 -13 105 -14 105 -3 0 5 -28 14 -63 21 -79 16 -113 35 -44 26 72
-10 56 2 -30 21 -166 36 -405 127 -431 165 -7 10 -31 28 -53 39 -21 11 -39 25
-39 30 0 6 -7 10 -16 10 -16 0 -66 25 -94 46 -37 29 -253 135 -315 155 -39 12
-88 31 -110 42 -39 20 -193 38 -322 39 -37 0 -53 4 -53 13 0 26 -27 37 -52 22
-33 -21 -167 -20 -191 1 -17 15 -22 15 -60 -6 -23 -12 -48 -22 -54 -22 -7 0
-13 -4 -13 -9 0 -5 -26 -19 -57 -32 -32 -12 -61 -27 -64 -32 -4 -5 -12 -6 -19
-2 -6 4 -20 -2 -31 -14 -11 -12 -24 -21 -29 -21 -6 0 -10 -6 -10 -14 0 -7 -10
-16 -21 -19 -13 -4 -20 -11 -16 -20 3 -8 -7 -24 -24 -38 -20 -16 -27 -30 -23
-42 4 -12 -9 -37 -38 -75 -55 -73 -68 -103 -68 -163 0 -61 17 -99 44 -99 20 0
20 0 -1 -15 -23 -16 -43 -70 -43 -118 0 -34 37 -87 62 -87 31 0 20 -14 -27
-35 -25 -11 -45 -23 -45 -28 0 -4 9 -3 19 3 30 16 98 13 105 -5 3 -8 14 -15
25 -15 20 0 35 25 25 42 -6 10 23 48 38 48 5 0 26 11 48 25 48 30 59 32 35 5
-10 -11 -15 -23 -11 -27 4 -4 11 -1 16 7 7 10 10 11 10 3 0 -7 7 -13 15 -13 8
0 15 -7 15 -15 0 -9 -9 -15 -25 -15 -34 0 -32 -18 3 -25 70 -15 62 -22 67 63
1 15 10 40 20 56 26 39 9 53 -43 38 -84 -25 -93 -8 -32 56 24 24 50 42 63 42
17 0 19 2 8 9 -12 8 -12 12 -2 25 8 9 21 16 30 16 9 0 22 5 29 12 7 7 12 9 12
6 0 -4 15 -1 33 7 37 15 57 6 57 -27 0 -22 39 -68 57 -68 10 0 12 -7 7 -22 -6
-19 -4 -21 7 -12 8 6 31 12 51 12 29 1 44 -5 63 -25 13 -15 24 -36 24 -48 -1
-11 3 -34 9 -51 7 -22 7 -35 -1 -43 -7 -7 -6 -15 3 -26 7 -8 10 -26 8 -40 -3
-14 0 -33 7 -42 7 -9 11 -23 8 -30 -3 -7 -1 -13 4 -13 14 0 29 -41 25 -67 -3
-19 -10 -23 -36 -23 -18 0 -58 -5 -89 -11 -55 -11 -127 -6 -127 10 0 5 13 14
29 21 18 7 28 17 25 25 -8 19 -35 28 -48 15 -7 -7 -3 -10 13 -10 12 0 20 -4
17 -9 -13 -20 -42 3 -65 51 -18 36 -21 53 -13 56 20 6 14 22 -8 22 -26 0 -152
-31 -165 -40 -12 -9 -97 -19 -217 -25 -96 -6 -183 -32 -226 -71 -56 -49 -63
-57 -52 -68 5 -5 10 -18 10 -27 0 -33 40 -47 117 -42 61 5 78 2 127 -20 31
-14 55 -32 54 -38 -3 -14 37 -26 95 -28 25 -1 37 -6 37 -15 0 -8 13 -24 30
-36 41 -29 40 -51 -2 -43 -18 3 -37 9 -43 13 -5 4 -43 17 -82 30 -40 12 -73
25 -73 30 0 4 -17 8 -37 7 -63 -1 -325 23 -368 35 -22 5 -49 17 -59 26 -13 11
-17 12 -11 3 6 -10 4 -12 -4 -6 -8 4 -10 17 -7 31 7 27 -14 137 -47 238 -16
51 -18 71 -9 74 9 3 9 8 0 25 -20 37 -16 271 7 407 13 72 58 226 85 284 10 23
22 55 26 71 4 17 12 37 19 45 6 8 22 37 35 63 12 27 29 51 36 54 8 3 14 10 14
15 0 18 53 80 176 203 140 141 201 184 399 284 66 33 321 97 448 112 56 6 110
15 120 18 25 9 118 4 332 -20z m-996 -1261 c-6 -12 -16 -27 -20 -32 -45 -57
-58 -75 -72 -102 -9 -17 -23 -36 -32 -44 -22 -18 -59 0 -78 39 -14 28 -13 31
7 50 11 11 35 22 51 25 17 3 41 18 55 34 29 33 57 51 83 52 15 0 16 -3 6 -22z
m690 1 c17 -6 31 -15 31 -19 0 -13 81 -57 119 -65 18 -4 83 -29 143 -56 60
-27 112 -48 116 -47 24 5 85 -17 89 -33 3 -10 11 -15 19 -12 8 3 17 -2 20 -12
4 -10 16 -15 33 -13 21 2 25 -1 23 -17 -13 -73 -4 -93 78 -178 43 -45 106
-117 140 -160 57 -73 60 -79 43 -92 -12 -9 -23 -10 -36 -3 -27 14 -46 -6 -30
-30 15 -24 -10 -63 -29 -44 -18 18 -33 15 -59 -13 -13 -13 -27 -25 -30 -25
-10 0 -65 94 -73 123 -3 14 -16 32 -28 38 -17 9 -20 9 -16 -2 3 -8 13 -42 23
-76 10 -34 21 -60 25 -58 4 2 7 -3 7 -11 0 -11 -10 -14 -36 -12 -35 3 -46 15
-67 73 -6 17 -8 14 -13 -15 -8 -46 -28 -45 -58 3 -19 31 -21 37 -7 37 12 0 14
5 8 18 -4 9 -8 43 -8 75 -1 37 -6 61 -15 66 -8 5 -9 11 -3 15 12 7 46 -30 38
-43 -4 -5 -2 -12 4 -16 5 -3 10 -19 10 -34 0 -31 16 -52 36 -44 14 5 20 24 7
22 -20 -3 -25 2 -14 15 12 14 10 50 -2 62 -4 4 -7 17 -7 29 0 12 -9 31 -21 43
-11 12 -17 27 -14 33 4 5 0 21 -9 34 -9 13 -16 31 -16 39 0 37 -90 101 -182
130 -10 3 -18 12 -18 21 0 14 2 14 24 0 28 -19 116 -28 137 -14 9 6 -2 9 -36
9 -28 0 -59 7 -69 15 -11 8 -30 15 -43 15 -27 0 -30 15 -5 29 16 10 15 10 -3
11 -60 0 -81 -25 -49 -58 13 -13 15 -29 10 -84 -9 -102 -54 -177 -78 -133 -5
8 -15 12 -21 9 -7 -4 -9 -4 -5 1 4 4 4 13 -1 19 -5 6 -12 32 -14 58 -3 26 -8
52 -12 58 -10 16 -13 180 -4 180 14 0 10 27 -5 33 -7 3 -34 9 -60 12 -50 8
-76 28 -29 23 18 -2 27 1 26 10 -2 6 2 12 7 12 6 0 10 5 10 10 0 6 -15 10 -32
10 -39 0 -63 13 -54 29 9 14 44 14 85 0z m621 -134 c25 -2 46 -4 48 -5 1 -2 7
-4 12 -5 6 -1 13 -4 18 -5 4 -1 11 -2 16 -1 4 1 31 -5 60 -13 28 -8 94 -22
146 -31 52 -9 107 -23 123 -31 15 -7 34 -11 42 -8 8 4 15 1 15 -4 0 -6 26 -13
58 -17 70 -8 180 -39 247 -70 28 -13 73 -33 100 -45 l50 -21 -52 6 c-28 3 -64
12 -79 20 -14 7 -50 16 -78 20 -28 4 -89 18 -136 32 -47 15 -105 30 -130 34
-218 36 -304 49 -355 53 -79 8 -145 22 -190 41 -21 8 -43 15 -50 15 -7 0 -30
8 -51 17 l-39 17 30 7 c17 4 57 5 90 2 33 -3 80 -7 105 -8z m195 -155 c3 -5
12 -7 19 -4 12 5 28 -11 102 -101 43 -52 79 -85 92 -85 17 0 15 7 -8 40 -11
16 -16 31 -11 34 4 3 34 -25 65 -62 l57 -67 -31 -9 c-35 -10 -96 16 -106 46
-3 9 -13 20 -23 23 -10 3 -31 25 -47 48 -15 23 -52 66 -81 95 -30 28 -50 52
-44 52 5 0 12 -4 16 -10z m-905 -234 c0 -25 -1 -25 -100 -25 -102 0 -130 -5
-112 -23 6 -6 17 -5 30 3 12 7 22 11 24 9 2 -3 14 -18 28 -33 l25 -28 -43 4
c-24 3 -49 10 -56 16 -7 6 -30 17 -52 24 -32 11 -43 11 -64 0 -32 -16 -70 -17
-70 0 0 9 -11 12 -35 9 -19 -2 -35 0 -35 6 0 32 96 53 132 30 35 -23 98 -30
114 -13 8 7 40 16 71 19 32 4 74 10 93 15 19 5 38 9 43 10 4 0 7 -10 7 -23z
m180 -175 c0 -11 5 -23 10 -26 6 -4 7 -11 4 -17 -4 -7 -2 -8 4 -4 7 4 12 3 12
-2 0 -5 9 -12 20 -15 12 -3 18 -1 14 5 -7 11 27 7 74 -7 18 -6 22 -3 22 12 0
11 6 27 13 37 13 17 15 17 45 2 31 -16 49 -56 58 -126 12 -101 23 -120 68
-120 30 0 56 -15 56 -31 0 -12 -46 -59 -57 -59 -5 0 -29 -18 -53 -40 -28 -25
-53 -40 -69 -40 l-26 0 24 19 c17 13 20 20 11 26 -8 5 -9 10 -1 18 14 14 14
54 0 63 -6 3 -9 15 -6 25 3 11 0 19 -7 19 -12 0 -26 -22 -41 -65 -6 -16 -9
-17 -13 -5 -7 19 -28 40 -40 40 -14 0 -31 -46 -25 -68 4 -16 0 -21 -13 -20
-11 1 -31 -1 -46 -5 -23 -6 -27 -4 -33 23 -4 17 -10 30 -13 30 -10 0 -31 48
-23 55 3 4 6 20 7 36 1 24 -2 29 -22 29 -32 0 -40 -18 -12 -29 26 -11 24 -13
-14 -15 -15 -1 -24 2 -22 6 3 4 1 8 -5 8 -16 0 -33 39 -26 60 4 11 13 20 22
20 8 0 13 6 11 13 -6 17 -49 22 -58 6 -8 -12 -14 -10 -60 16 -14 8 -19 15 -12
15 9 0 11 7 7 20 -3 11 -2 20 3 20 5 0 12 9 15 20 3 11 12 20 21 20 9 0 13 6
10 14 -4 9 12 16 58 23 99 16 108 15 108 -6z m-1220 -29 c106 -15 179 -32 186
-43 3 -5 19 -9 35 -9 43 0 71 -17 64 -39 -3 -11 4 -28 20 -45 21 -23 23 -31
14 -48 -10 -18 -9 -20 5 -15 10 4 16 1 16 -7 0 -8 -10 -17 -22 -21 -20 -6 -20
-8 -5 -12 9 -2 17 -9 17 -15 0 -6 11 1 25 16 31 34 31 44 0 80 -30 35 -35 72
-7 57 46 -25 94 -113 71 -131 -3 -3 -9 -16 -13 -30 -5 -18 -5 -21 3 -10 9 12
11 12 17 -3 3 -9 15 -17 26 -17 15 0 19 -5 15 -19 -6 -24 18 -46 58 -54 17 -3
38 -9 48 -12 12 -5 17 -2 17 8 0 24 21 31 27 9 5 -19 76 -56 87 -44 11 10 6
19 -18 30 -40 18 -89 135 -61 146 17 6 100 -84 97 -105 -2 -12 8 -19 34 -23
20 -4 47 -18 61 -32 13 -13 26 -24 28 -24 8 0 55 -51 55 -60 0 -6 5 -10 10
-10 6 0 10 -6 10 -14 0 -7 13 -16 29 -19 22 -5 35 -16 48 -44 10 -21 16 -46
15 -56 -2 -12 4 -20 16 -24 11 -3 23 -16 26 -29 4 -13 13 -24 20 -24 8 0 17
-7 20 -16 3 -8 10 -13 15 -9 5 3 12 -4 16 -15 4 -12 27 -29 56 -41 57 -25 65
-44 14 -36 -20 3 -35 2 -35 -4 0 -13 -127 0 -160 16 -14 7 -50 15 -80 19 -68
9 -81 12 -100 19 -8 3 -39 11 -67 17 -29 7 -53 16 -53 22 0 5 -3 7 -7 5 -5 -3
-37 10 -73 28 -36 19 -84 43 -107 54 -23 11 -48 24 -55 30 -25 21 -139 91
-148 91 -6 0 -10 6 -10 14 0 7 -19 26 -42 42 -23 16 -51 38 -63 49 -12 11 -43
38 -69 60 -58 49 -226 248 -226 268 0 4 -11 23 -25 41 -46 60 -43 61 125 38z
m1692 -294 c12 -7 34 -27 50 -45 16 -17 42 -34 57 -38 33 -7 66 -39 56 -54 -3
-6 -23 -11 -44 -11 -30 0 -40 5 -51 25 -7 14 -17 25 -22 25 -4 0 -17 9 -28 20
-35 35 -66 26 -155 -50 -45 -38 -91 -70 -103 -70 -25 0 -44 23 -36 43 6 16 61
34 71 23 3 -3 15 9 27 26 11 17 44 43 73 57 28 14 53 34 56 44 6 20 19 22 49
5z"/>
<path d="M1860 3406 c-3 -3 -32 -5 -65 -6 -89 -3 -295 -50 -370 -86 -56 -26
-180 -96 -196 -110 -8 -8 -21 -14 -27 -14 -7 0 -12 -4 -12 -10 0 -5 -5 -10
-11 -10 -6 0 -27 -12 -47 -27 -20 -16 -43 -32 -50 -38 -8 -5 -49 -46 -93 -89
-64 -64 -77 -81 -64 -88 20 -12 19 -28 -3 -28 -10 0 -27 -7 -39 -15 -14 -10
-24 -12 -28 -5 -9 15 -25 2 -25 -21 0 -10 -16 -42 -36 -70 -19 -28 -32 -54
-28 -57 4 -4 9 -1 12 6 2 8 18 29 35 48 24 28 36 34 61 32 25 -2 30 1 28 14
-2 15 3 17 26 12 36 -7 50 11 21 26 -31 17 -11 30 49 33 57 2 67 17 25 35 -28
11 -27 11 10 9 20 -2 37 1 37 5 0 4 -4 8 -10 8 -5 0 -10 4 -10 9 0 4 10 7 23
6 12 -1 28 -3 35 -4 7 0 10 -6 6 -13 -4 -7 -3 -8 4 -4 7 4 12 16 12 27 0 15 7
19 31 19 17 0 28 -4 24 -10 -15 -24 13 -7 34 20 19 27 20 31 6 37 -14 5 -12
10 12 29 15 13 43 26 61 30 18 3 35 10 38 15 8 14 24 10 24 -6 0 -8 -6 -15
-14 -15 -14 0 -26 -29 -26 -68 0 -14 -11 -29 -32 -42 -22 -13 -27 -20 -15 -20
11 0 17 -8 17 -25 0 -14 5 -25 10 -25 6 0 10 -6 10 -14 0 -17 51 -30 81 -21
25 8 59 40 60 58 0 6 9 26 20 42 10 17 22 37 26 45 4 8 13 20 20 25 7 6 14 26
17 45 7 54 34 100 59 100 14 0 27 10 35 27 16 35 15 43 -2 43 -22 0 -30 19
-11 26 10 4 25 -4 40 -20 13 -14 34 -26 46 -26 21 0 21 -1 5 -19 -10 -11 -16
-26 -13 -33 2 -7 0 -21 -5 -31 -8 -15 0 -21 47 -43 64 -28 68 -49 9 -50 -69
-1 -84 -4 -84 -16 0 -6 -7 -20 -17 -30 -14 -16 -15 -21 -2 -46 19 -36 38 -50
61 -44 10 2 18 -1 18 -7 0 -7 6 -11 13 -10 28 5 64 -14 81 -42 11 -19 25 -29
41 -29 13 0 27 -4 30 -10 3 -5 31 -10 60 -10 49 0 55 3 60 23 4 12 2 35 -4 49
-5 14 -7 29 -3 33 3 3 -1 3 -11 -1 -10 -3 -17 -1 -17 5 0 15 -86 52 -104 45
-9 -3 -16 -1 -16 4 0 10 28 17 90 25 8 1 16 15 18 30 2 20 8 27 17 23 8 -3 31
2 50 10 20 8 39 13 43 10 4 -3 17 9 27 25 11 16 27 29 36 29 20 0 30 31 17 54
-12 21 14 49 36 40 20 -8 21 -24 1 -24 -8 0 -15 -5 -15 -11 0 -6 9 -8 23 -4
16 5 18 4 8 -3 -8 -6 -11 -17 -7 -27 6 -16 8 -16 26 5 20 23 45 21 85 -6 11
-8 29 -14 42 -14 12 0 25 -4 28 -10 3 -5 15 -10 25 -10 10 0 34 -8 52 -18 18
-11 50 -18 70 -18 21 1 38 -3 38 -7 0 -11 64 -37 91 -37 34 0 16 22 -32 39
-24 9 -46 19 -49 23 -3 4 -14 6 -25 5 -14 -1 -21 5 -23 21 -4 25 -24 29 -42 7
-15 -18 -34 -9 -26 12 4 10 1 13 -13 8 -11 -3 -22 -1 -26 5 -3 6 -11 8 -16 5
-5 -4 -9 -1 -9 4 0 6 10 11 22 11 35 0 14 10 -25 12 -21 1 -38 7 -42 16 -3 8
-12 11 -20 8 -8 -3 -15 -1 -15 3 0 5 6 12 13 14 7 3 5 6 -5 6 -9 1 -22 -7 -27
-16 -6 -10 -10 -13 -10 -8 0 6 6 19 14 29 18 23 4 46 -28 46 -15 0 -18 3 -10
11 15 15 60 0 66 -22 5 -20 27 -27 27 -9 0 13 57 13 65 0 4 -6 11 -7 17 -4 6
4 17 0 23 -10 13 -17 50 -12 39 5 -3 5 4 9 15 9 12 0 21 5 21 10 0 6 -13 10
-30 10 -20 0 -30 5 -30 15 0 23 46 18 70 -7 11 -12 20 -25 20 -29 0 -4 14 -12
32 -18 17 -6 29 -13 27 -15 -7 -7 -59 6 -59 15 0 5 -7 9 -15 9 -18 0 -18 -2
-4 -33 8 -19 16 -22 40 -19 17 3 29 0 29 -7 0 -6 5 -11 11 -11 5 0 7 5 4 10
-4 6 5 10 21 10 22 0 24 3 15 14 -7 8 -8 17 -4 19 13 8 37 -9 29 -21 -3 -6 10
-12 32 -15 20 -3 47 -8 60 -12 17 -6 22 -3 22 9 0 9 -8 16 -17 16 -10 0 -29 9
-43 20 -14 11 -28 20 -31 20 -4 0 -28 10 -55 22 -27 12 -56 23 -64 24 -8 1
-40 11 -71 23 -31 12 -74 23 -95 26 -22 2 -49 7 -61 11 -16 4 -23 2 -23 -8 0
-7 -9 -14 -20 -15 -16 -1 -20 3 -15 16 5 15 -2 19 -47 24 -30 4 -57 2 -62 -3
-14 -14 -46 -12 -46 3 0 9 -37 13 -143 15 -78 2 -145 1 -147 -2z m-37 -138 c3
-10 9 -10 25 -1 17 10 24 9 44 -6 18 -14 37 -18 87 -15 l64 2 -28 -24 c-15
-13 -35 -24 -44 -24 -13 -1 -12 -3 3 -11 13 -8 29 -8 56 1 44 14 60 8 53 -20
-7 -25 -65 -37 -108 -21 -37 13 -95 15 -95 2 0 -5 7 -14 15 -21 19 -16 19 -36
1 -43 -8 -3 -33 12 -57 34 -23 21 -52 39 -64 39 -27 0 -49 24 -42 45 4 8 18
17 33 21 22 6 31 3 42 -14 10 -15 24 -22 46 -21 45 1 67 11 57 28 -6 9 -11 10
-15 2 -4 -6 -13 -11 -21 -11 -16 0 -75 48 -75 61 0 14 18 11 23 -3z"/>
<path d="M2214 3119 l-42 -10 11 -34 c5 -19 23 -50 39 -70 24 -28 34 -34 54
-29 14 4 31 1 41 -7 9 -8 19 -13 22 -12 3 1 5 -9 4 -23 -3 -37 -48 -125 -77
-153 -32 -30 -33 -51 -3 -79 38 -36 127 -25 127 16 0 41 35 159 53 180 24 26
53 28 77 7 10 -9 33 -18 52 -22 31 -4 38 -1 57 25 35 50 21 79 -48 98 -31 9
-74 26 -96 37 -22 12 -54 21 -71 22 -18 1 -44 11 -59 23 -15 12 -36 22 -46 22
-10 0 -21 5 -24 10 -7 11 -15 11 -71 -1z"/>
<path d="M2750 2960 c12 -7 9 -11 -16 -20 -29 -10 -54 -44 -54 -74 0 -7 -10
-28 -23 -47 -13 -19 -38 -71 -56 -116 -18 -45 -41 -89 -52 -99 -42 -38 -12
-104 47 -104 22 0 34 7 47 29 9 16 17 37 17 48 0 10 11 31 25 46 25 26 27 27
56 11 18 -9 50 -14 77 -13 104 6 140 113 69 203 -18 23 -35 43 -40 44 -16 7
-40 66 -28 70 6 2 11 11 11 20 -1 15 -2 15 -11 0 -12 -22 -49 -24 -49 -3 0 8
-8 15 -17 15 -15 0 -16 -2 -3 -10z"/>
<path d="M2423 2228 c15 -7 27 -15 27 -20 0 -4 12 -8 26 -8 15 0 43 -4 63 -10
20 -5 63 -16 96 -24 169 -41 295 -83 295 -98 0 -6 10 -8 21 -5 12 3 31 0 43
-8 21 -13 143 -36 188 -34 23 0 23 0 -2 11 -31 12 -109 34 -175 49 -27 6 -81
21 -120 34 -167 54 -296 88 -460 119 l-30 6 28 -12z"/>
</g>
</svg>
);

export default IconNumismatics;
